/* eslint-disable global-require */
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { createGlobalStyle } from 'styled-components'
import { UIText } from '../UI'

const moduleIsExist = (src) => {
  try {
    return `url(${require(`../../fonts/${src}`)})`
  } catch (fontModuleError) {
    return ''
  }
}

/*
const fontFace = (name, src, fontWeight = 'normal', fontStyle = 'normal') => `
        @font-face{
            font-family: "${name}";
            src: url(${require(`../../fonts/${src}.eot`)});
            src: url(${require(`../../fonts/${src}.eot`)}?#iefix) format("embedded-opentype"),
                 url(${require(`../../fonts/${src}.woff`)}) format("woff"),
                 url(${require(`../../fonts/${src}.ttf`)}) format("truetype"),
                 url(${require(`../../fonts/${src}.svg`)}#${name}) format("svg");

            font-style: ${fontStyle};
            font-weight: ${fontWeight};
        }
    `
*/

const fontFace = (name, src, fontWeight = 'normal', fontStyle = 'normal') => `
        @font-face{
            font-family: "${name}";
            font-display: auto;
            src: ${moduleIsExist(`${src}.otf`) || moduleIsExist(`${src}.ttf`)};

            font-style: ${fontStyle};
            font-weight: ${fontWeight};
        }
    `

const font2src = {
  Roboto: { file: 'Roboto-Regular' },
  RobotoSlab: { file: 'RobotoSlab-Bold' },
  SourceSansProBold: { file: 'SourceSansPro-Bold' },
  SourceSansPro: { file: 'SourceSansPro-Regular' },
  AXIS: { file: 'Axis' },
  RistrettoPro: { file: 'RistrettoPro-Regular' },
  RistrettoBold: { file: 'RistrettoPro-Bold' },
  GinesoNorm: { file: 'Gineso-Norm-Regular' },
  GinesoNormDem: { file: 'Gineso-Norm-Demi' },
  GinesoNormLig: { file: 'Gineso-Norm-Light' },
  GTWalsheimProMedium: { file: 'gt-walsheim-medium' },
  Cabin: { file: 'Cabin-Regular' },
  CabinBold: { file: 'Cabin-Bold' },
  OpenSans: { file: 'OpenSans-SemiBold' },
  OpenSansBold: { file: 'OpenSans-Bold' },
  Montserrat: { file: 'Montserrat-Regular' },
  MontserratBold: { file: 'Montserrat-Bold' },
  Lato: { file: 'Lato-Regular' },
  LatoMedium: { file: 'Lato-Medium' },
  LatoBold: { file: 'Lato-Bold' },
  FiraSans: { file: 'FiraSans-Regular' },
  FiraSansBold: { file: 'FiraSans-Bold' },
  FiraSansExtraBold: { file: 'FiraSans-ExtraBold' },
  ADPortsGroup: { file: 'ADPortsGroup-Regular' },
  ADPortsGroupBold: { file: 'ADPortsGroup-Bold' },
  ADPortsGroupLight: { file: 'ADPortsGroup-Light' },
  Mulish: { file: 'Mulish-Regular' },
  MulishBold: { file: 'Mulish-Bold' },
  Eina01Bold: { file: 'Eina01-Bold' },
  Eina01Regular: { file: 'Eina01-Regular' },
  Eina01SemiBold: { file: 'Eina01-SemiBold' },
}

const fontFromUrl = {
  ProximaSoft: `@font-face {
    font-family:"ProximaSoft";
    src:url("https://use.typekit.net/af/5beeef/00000000000000003b9af203/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/5beeef/00000000000000003b9af203/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/5beeef/00000000000000003b9af203/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-style:normal;font-weight:500;
    }`,
  ProximaSoftBold: `@font-face {
    font-family:"ProximaSoftBold";
    src:url("https://use.typekit.net/af/bd143b/00000000000000003b9af20a/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/bd143b/00000000000000003b9af20a/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/bd143b/00000000000000003b9af20a/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
    font-style:normal;font-weight:600;
    }`,
  AdelleSans: `@font-face {
    font-family:"AdelleSans";
    src:url("https://use.typekit.net/af/03e1a3/00000000000000003b9acf2d/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/03e1a3/00000000000000003b9acf2d/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/03e1a3/00000000000000003b9acf2d/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-style:normal;font-weight:400;
    }`,
  AdelleSansBold: `@font-face {
    font-family:"AdelleSansBold";
    src:url("https://use.typekit.net/af/9f2f2a/00000000000000003b9acf30/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/9f2f2a/00000000000000003b9acf30/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/9f2f2a/00000000000000003b9acf30/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
    font-style:normal;font-weight:700;
    }`,
  AdelleSansExtraBold: `@font-face {
    font-family:"AdelleSansExtraBold";
    src:url("https://use.typekit.net/af/c6dcf7/000000000000000000012b01/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("woff2"),url("https://use.typekit.net/af/c6dcf7/000000000000000000012b01/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("woff"),url("https://use.typekit.net/af/c6dcf7/000000000000000000012b01/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("opentype");
    font-style:normal;font-weight:800;
    }`,
}

const Fonts = (props, context) => {
  const { muiTheme } = context

  const idFonts = Array.prototype.concat(
    ...Object.keys(muiTheme.ids).map((id) => (muiTheme.ids[id].font ? muiTheme.ids[id].font.split(',') : []))
  )
  const baseThemeFonts = Array.prototype.concat(...muiTheme.baseTheme.fontFamily.split(','))
  const rawThemeFonts = Array.prototype.concat(...muiTheme.rawTheme.fontFamily.split(','))
  const miscellaneousFonts = Array.prototype.concat(...muiTheme.fontFamily.split(','))

  const virtualFonts = ['sans-serif']
  const setFonts = new Set(
    Array.prototype.concat(...[idFonts, baseThemeFonts, rawThemeFonts, miscellaneousFonts, Object.keys(font2src)])
  )

  const uniqueFonts = Array.from(setFonts)
    .map((fontName) => fontName.trim())
    .filter((val) => !virtualFonts.includes(val))

  const skippedFonts = []
  const fontFaces = Array.prototype.concat(
    ...uniqueFonts.map((font) => {
      if (font2src && font2src[font]) {
        return [].concat(font2src[font]).map((descr) => fontFace(font, descr.file, descr.weight, descr.style))
      }
      if (fontFromUrl && fontFromUrl[font]) {
        return [].concat(fontFromUrl[font])
      }
      skippedFonts.push(font)
      return []
    })
  )

  const GlobalFonts = createGlobalStyle`${fontFaces.join('')}`

  return (
    <Fragment>
      <GlobalFonts />

      <UIText color={'red'} display-if={false}>
        {`Skipped fonts: ${skippedFonts.join(',')}`}
      </UIText>
    </Fragment>
  )
}

Fonts.contextTypes = {
  muiTheme: PropTypes.object,
}

Fonts.propTypes = {}

Fonts.defaultProps = {
  font2src: {},
}

export default Fonts
