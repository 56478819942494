/* eslint-disable no-param-reassign */
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { find, propEq } from 'ramda'

import { UIAvailabilityBar, UIIcon, UILayout } from '../../UI'

import Time from './Time'
import { THEME } from '../../../themes'
import { getStyle } from 'bora/src/utils/liinilaevadThemeUtils'
import { getGavdosStyle } from 'bora/src/utils/gavdosStyleUtils'
import { getMaritimeStyle, isMaritime, isBluewave } from 'bora/src/utils/maritimeStyleUtils'
import moduleStyles from './TicketCard.module.scss'

const xsStyles = {
  size14: { size: '14px' },
  width75: { width: '75px' },
  width75MarginRight33: { width: '75px', marginRight: window.brandProps.theme === THEME.GAVDOS ? '33%' : 'inherit' },
  width130: { width: '130px' },
  size14Padding5: { size: '14px', padding: '5px' },
  iconArrow: { width: '10px', height: '10px', margin: '0 3px' },
  ticketCard: { padding: '10px 0px', 'j-flex-space-between': true },
}

const style = getComputedStyle(document.body)
const bluewavePrimaryColor = style.getPropertyValue('--calendar-selected-bg')
const primaryColor = isBluewave ? bluewavePrimaryColor : '#13C5BD'

export default class TicketCard extends PureComponent {
  static propTypes = {
    header: PropTypes.string.isRequired,
    ticketSelected: PropTypes.bool,
    availabilityTickets: PropTypes.array.isRequired,
    onSelect: PropTypes.func,
    arrival: PropTypes.object.isRequired,
    departure: PropTypes.object.isRequired,
    isDangerous: PropTypes.bool,
    isRestrictedPrices: PropTypes.bool,
    isClosed: PropTypes.bool,
    isCancelled: PropTypes.bool,
    voyageCapacityError: PropTypes.bool,
    route: PropTypes.string,
    vesselFeatures: PropTypes.object,
    hasOnlyPassengers: PropTypes.bool,
    hasOnlySeatsAndCars: PropTypes.bool,
    onEditMode: PropTypes.bool,
    isNextDay: PropTypes.bool,
  }

  static contextTypes = {
    muiTheme: PropTypes.object,
  }

  static defaultProps = {
    header: '',
    showArrivalTip: false,
    soldOutLabel: '',
    arrival: {},
    departure: {},
    availabilityTickets: [],
    onSelect: undefined,
    isDangerous: false,
    isRestrictedPrices: false,
    isClosed: false,
    isCancelled: false,
    route: undefined,
    voyageCapacityError: false,
    vesselFeatures: {},
    onEditMode: false,
    isNextDay: false,
  }

  isTicketsSoldOut = () => {
    const { availabilityTickets } = this.props

    return availabilityTickets
      .filter(({ type }) => type !== 'REEFER_SOCKER')
      .every((ticketType) => ticketType.outOfStock)
  }

  isSelectAllowed = () => {
    const { isClosed, isCancelled, voyageCapacityError } = this.props

    return !voyageCapacityError && !isClosed && !isCancelled && !this.isTicketsSoldOut()
  }

  handleSelectTicket = () => {
    const { onSelect } = this.props

    if (!this.isSelectAllowed() || !onSelect) {
      return
    }

    onSelect()
  }

  // eslint-disable-next-line complexity
  render() {
    const {
      availabilityTickets,
      ticketSelected,
      arrival,
      departure,
      isDangerous,
      isRestrictedPrices = false,
      isClosed,
      isCancelled,
      isHovercraft,
      route,
      voyageCapacityError = false,
      vesselFeatures,
      hasOnlyPassengers = false,
      hasOnlySeatsAndCars = false,
      locale,
      supportCars,
      supportPassengers,
      supportTrucks,
      supportBicycles,
      isNextDay,
    } = this.props
    const { muiTheme } = this.context

    const availableBikes = find(propEq('type', 'Bike'), availabilityTickets)
    const availableCars = find(propEq('type', 'Car'), availabilityTickets)
    const { hasBicycleSpots, hasSeats, hasCarDecks, hasCabins } = vesselFeatures
    const hasOnlySeatsAndBikes = hasBicycleSpots && hasSeats && !hasCarDecks && !hasCabins

    const normalCursor = this.props.onSelect ? 'pointer' : 'default'
    const cursor = this.isSelectAllowed() ? normalCursor : 'not-allowed'
    const selectedColor = muiTheme.palette && muiTheme.palette.selectedItem ? muiTheme.palette.selectedItem : '#edf2ff'
    const unselectedColor =
      muiTheme.palette && muiTheme.palette.unselectedItem ? muiTheme.palette.unselectedItem : '#fdfdfd'

    const selectedBorder =
      muiTheme.palette && muiTheme.palette.selectedItem
        ? `1px solid ${getMaritimeStyle(primaryColor, muiTheme.palette.selectedItem)}`
        : '1px solid #4266bd'
    const unselectedBorder =
      muiTheme.palette && muiTheme.palette.unselectedItem
        ? `1px solid ${muiTheme.palette.unselectedItem}`
        : ' 1px solid #ebebeb'

    const borderRadius = getMaritimeStyle(
      '10px',
      muiTheme.ids.routeBanner && muiTheme.ids.routeBanner.borderRadius ? muiTheme.ids.routeBanner.borderRadius : '4px'
    )

    const casualColor = ticketSelected ? selectedColor : unselectedColor
    const bgColor = isCancelled ? '#fff6f6' : casualColor

    const casualBorder = ticketSelected ? selectedBorder : unselectedBorder
    const border = isCancelled ? '1px solid #d0021b' : casualBorder

    return (
      <UILayout
        row
        width="100%"
        padding="10px 0"
        margin="0 0 15px 0"
        bgColor={bgColor}
        cursor={cursor}
        maxWidth="100%"
        onClick={this.handleSelectTicket}
        data-test="ticket-card"
        j-flex-space-between={!isMaritime || !(supportCars && supportTrucks)}
        border={border}
        borderRadius={borderRadius}
        opacity={voyageCapacityError ? 0.4 : 1}
        xs={xsStyles.ticketCard}
        className={moduleStyles.row}
      >
        <UILayout
          width={isCancelled && hasOnlySeatsAndBikes ? '190px' : '210px'}
          shrink={0}
          j-flex-center
          center
          className={moduleStyles.timeColumn}
          xs={{ width: getGavdosStyle('auto', '70px') }}
        >
          <Time
            departureTime={departure.time}
            arrivalTime={arrival.time}
            isClosed={isClosed}
            isSelected={ticketSelected}
            isNextDay={isNextDay}
            isCancelled={isCancelled}
            isDangerous={isDangerous}
            isHovercraft={isHovercraft}
            isRestrictedPrices={isRestrictedPrices && !hasOnlyPassengers && !hasOnlySeatsAndBikes}
          />
          <UILayout
            display-if={muiTheme.name !== THEME.SAAREMAA && muiTheme.name !== THEME.KIHNU}
            data-test="special-icons-for-this-sail"
            width={muiTheme.name !== THEME.SAAREMAA ? getStyle('85px', '60px') : '50px'}
            height="25px"
            j-flex-space-between={getStyle(false, true)}
            style={isMaritime ? { flex: 1, justifyContent: 'center', alignItems: 'center' } : {}}
            xs={{ width: '18px', height: '18px' }}
          >
            <UIIcon
              className={moduleStyles.rtlIcon}
              type="iconDanger"
              margin={getMaritimeStyle('0 2px', getStyle('0 5px 0 0'))}
              display-if={isDangerous}
              xs={{ width: '18px', height: '18px' }}
            />
            <UIIcon
              className={moduleStyles.rtlIcon}
              type="iconNoHeavyTrucks"
              margin={getMaritimeStyle('0 2px', getStyle('0 5px 0 0'))}
              display-if={isRestrictedPrices}
              xs={{ width: '18px', height: '18px' }}
            />
            <UIIcon
              type="iconCancelledSail"
              margin={getMaritimeStyle('0 2px', getStyle('0 5px 0 0'))}
              width={getStyle('24px')}
              height={getStyle('24px')}
              display-if={isCancelled}
              xs={{ width: '18px', height: '18px' }}
            />
          </UILayout>
        </UILayout>

        <UILayout
          display-if={supportPassengers}
          column
          center
          j-flex-center
          xs={xsStyles.width75MarginRight33}
          className={moduleStyles.rowColumn}
        >
          <UILayout
            row
            center
            j-flex-center
            width="100%"
            xs={{ margin: getMaritimeStyle(locale === 'ar' ? '0 25px 0 0' : '0 0 0 25px') }}
          >
            <UIAvailabilityBar
              {...find(propEq('type', 'Passenger'), availabilityTickets)}
              hideAvailabilityMaxLabel
              hideIcon
              isCancelled={isCancelled}
              route={route}
            />
          </UILayout>
        </UILayout>

        <UILayout
          column
          center
          j-flex-center
          className={moduleStyles.rowColumn}
          display-if={
            ((availableBikes && !hasOnlyPassengers && !hasOnlySeatsAndCars) ||
              (isCancelled && !hasOnlySeatsAndCars && !hasOnlyPassengers)) &&
            !isHovercraft &&
            supportBicycles
          }
          width="110px"
          xs={xsStyles.width75}
        >
          <UILayout row center j-flex-center width="100%">
            <UIAvailabilityBar
              {...find(propEq('type', 'Bike'), availabilityTickets)}
              hideAvailabilityMaxLabel
              hideIcon
              isCancelled={isCancelled}
              route={route}
            />
          </UILayout>
        </UILayout>

        <UILayout
          column
          center
          j-flex-center
          className={moduleStyles.rowColumn}
          display-if={
            ((availableCars && !hasOnlySeatsAndBikes) || (isCancelled && availableCars && !hasOnlySeatsAndBikes)) &&
            !isHovercraft &&
            !hasOnlyPassengers &&
            (supportCars || supportTrucks)
          }
          xs={xsStyles.width75}
        >
          <UILayout row center j-flex-center width="100%">
            <UIAvailabilityBar
              {...find(propEq('type', 'Car'), availabilityTickets)}
              progressBarForTwoIcons={supportCars && supportTrucks && supportPassengers}
              longProgressBarForTwoIcons={supportCars && supportTrucks && !supportPassengers}
              hideProgressValue={supportCars && supportTrucks}
              hideAvailabilityMaxLabel
              hideIcon
              isCancelled={isCancelled}
              route={route}
            />
          </UILayout>
        </UILayout>
        <UILayout
          row
          center
          shrink={0}
          style={{ marginInlineEnd: '1rem', marginInlineStart: supportCars && supportTrucks ? 'auto' : '0' }}
          xs={{ marginInlineEnd: '15px', style: { marginInlineStart: '0' } }}
          className={moduleStyles.tickColumn}
        >
          <UIIcon
            display-if={ticketSelected}
            type={muiTheme.iconSelected || 'icon-sail-selected'}
            xs={{ width: '20px' }}
            fill={primaryColor}
          />
          <UIIcon display-if={voyageCapacityError} type="iconLock" xs={{ width: '20px' }} />
        </UILayout>
      </UILayout>
    )
  }
}
